<template>
  <v-app id="root" style="overflow: hidden">
    <v-app-bar app dense dark elevate-on-scroll color="primary" class="app-sidemenu">
      <v-col cols="2" class="text-center">
        <v-toolbar-title v-text="name" />
      </v-col>
      <v-spacer></v-spacer>
      <User-Menu :username="username" :roles="roles" :version="version" :loading="loading">
        <template v-slot:exit>
          <Basic-Btn text="Volver" color="disabled" @click="selectMenuAction = 2" :loading="loading"
            v-if="selectMenuAction < 2" />
          <Basic-Btn text="Guardar" color="success" @click="updateUser()" :loading="loading"
            v-if="selectMenuAction < 2" />
          <Basic-Btn text="Cerrar sesión" color="error" @click="logout()" :loading="loading"
            v-if="selectMenuAction == 2" />
        </template>
        <template v-slot:configureMenu>
          <v-form ref="formReset" lazy-validation @submit.prevent class="text-center">
            <v-list flat v-if="selectMenuAction == 2">
              <v-list-item-group v-model="selectMenuAction" class="text-left" color="primary">
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon v-text="'mdi-phone'" />
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="'Actualizar telefono'" />
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon v-text="'mdi-lock-reset'" />
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="'Actualizar PIN de seguridad'" />
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
            <div v-if="selectMenuAction == 1" class="pa-2">
              <h3 class="primary--text">Ingrese su nuevo PIN de seguridad.</h3>
              <br />
              <v-row>
                <v-col cols="12" class="py-0">
                  <PincodeInput :length="6" v-model="forms.pin_seguridad" placeholder="0" />
                </v-col>
                <v-col cols="12">
                  <v-btn text color="primary" class="text-none" @click="randomPIN()"> Generar PIN aleatorio </v-btn>
                </v-col>
              </v-row>
            </div>
            <div v-if="selectMenuAction == 0" class="pa-2">
              <h3 class="primary--text">Actualizar telefono</h3>
              <br />
              <v-row>
                <v-col cols="12" class="py-0">
                  <v-text-field v-model="forms.telefono" label="Teléfono" prepend-icon="mdi-phone"
                    :rules="rules.telefono" dense outlined></v-text-field>
                </v-col>
              </v-row>
            </div>
          </v-form>
        </template>
      </User-Menu>
      <v-btn icon color="white" @click="notify = !notify">
        <v-badge :content="notificationData.no_leidos.length" :value="notificationData.no_leidos.length" color="error" overlap>
          <v-icon small>mdi-bell</v-icon>
        </v-badge>
      </v-btn>
    </v-app-bar>
    <v-hover v-slot:default="{ hover }">
      <v-navigation-drawer fixed permanent dark dense hidden floating color="primary" class="app-sidemenu" mini-variant>
        <v-list dense nav>
          <v-list-item class="text-center">
            <v-avatar size="25px" class="pt-0">
              <img alt="Avatar" :src="logo" />
            </v-avatar>
          </v-list-item>
          <template v-for="item in items">
            <template v-if="item.children"> 
              <v-menu v-if="!validMenu(item.title)" right offset-x>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn dark v-bind="attrs" v-on="on" icon color="white">
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs}">
                          <Icon-Btn :label="item.title" :icon="item.icon" :loading="loading" color="white" right />
                      </template>
                    </v-tooltip>
                  </v-btn>
                </template>
                <v-list>
                  <div v-for="(child, i) in item.children" :key="i">
                    <v-list-item v-if="!validPermission(child.title)" :to="item.children[i].path" link>
                      <v-list-item-icon>
                        <v-icon v-text="child.icon" />
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title v-text="child.title" />
                      </v-list-item-content>
                    </v-list-item>
                  </div>
                </v-list>
              </v-menu>
              </v-list-group>
            </template>
            <div v-else  @click="$route.path == item.path ? '' : $router.push(item.path)">
              <v-btn dark icon color="white" v-if="!validPermission(item.title)">
                <v-tooltip right>
                  <template v-slot:activator="{ on3, attrs3 }">
                    <Icon-Btn :label="item.title" :icon="item.icon" color="white" right />
                  </template>
                </v-tooltip>
              </v-btn>
            </div>
          </template>
        </v-list>
        <template v-slot:append>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </template>
      </v-navigation-drawer>
    </v-hover>
    <v-main class="routerMain grey lighten-4">
      <Notification :open.sync="notify" :dialog.sync="dialogs.notification" :items="notificationData" v-on:update:open="notify = $event" @clicked-read="readNotification"></Notification>
      <router-view></router-view>
      <BasicAlert :active.sync="alert.active" :msg="alert.msg" :color="alert.color" />
      <v-dialog v-model="dialogs.notification" persistent max-width="290">
        <v-card>
          <v-card-title class="headline">Aviso</v-card-title>
          <v-card-text>¿Desea marcar la notificación como leída?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text small :disabled="loading" color="blue-grey" @click="idNotification = null; dialogs.notification = false">
              Cancelar
            </v-btn>
            <v-btn text small :loading="loading" color="success" @click="readNotification()">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-main>
  </v-app>
</template>

<script>
import { UserMenu, BasicBtn, Notification, IconBtn } from "@/components";
import PincodeInput from "vue-pincode-input";

export default {
  name: "root",
  components: {
    UserMenu,
    BasicBtn,
    Notification,
    PincodeInput,
    IconBtn,
  },
  data: () => ({
    host: process.env.VUE_APP_API_SERVER,
    version: process.env.VUE_APP_VERSION,
    name: process.env.VUE_APP_NAME,
    logo: require("@/assets/images/logo_c.png"),
    username: "",
    roles: [],
    notificationData: {
      no_leidos: [],
      leidos: []
    },
    idNotification: null,
    path: "/api/logout",
    pathUpdate: "/api/admin/user/profile",
    loading: false,
    notify: false,
    mode: 0,
    selectMenuAction: 2,
    forms: {
      pin_seguridad: "",
      telefono: "",
    },
    alert: {
      active: false,
      color: "error",
      msg: "",
    },
    items: [
      { icon: "mdi-home", title: "Home", path: "/home" },
      {
        icon: "mdi-file-document-edit-outline",
        title: "Solicitudes",
        children: [
          { icon: "mdi-file-document-plus-outline", title: "Nueva solicitud", path: "/nueva-solicitud-empleados" },
          { icon: "mdi-clipboard-text-clock-outline", title: "Ver todas las solicitudes", path: "/solicitud-empleados" },
        ],
      },
      {
        icon: "mdi-account-cog",
        title: "Administración de recursos humanos",
        children: [
          { icon: "mdi-clock", title: "Horarios", path: "/horarios" },
          { icon: "mdi-account-group", title: "Empleados", path: "/usuario" },
          { icon: "mdi-account-tie", title: "Puesto", path: "/puesto" },
          { icon: "mdi-briefcase-outline", title: "Departamento", path: "/departamento" },
          { icon: "mdi-airplane-cog", title: "Días festivos", path: "/dias-festivos" },
          { icon: "mdi-star", title: "Captura de vacaciones", path: "/captura-vacaciones" },
          { icon: "mdi-calendar-alert", title: "Tipos de incidencias", path: "/tipos-solicitud" },
          { icon: "mdi-calendar-alert-outline", title: "Subtipos de incidencias", path: "/subtipos-solicitud" },
          { icon: "mdi-account-details", title: "Incidencias de empleados", path: "/incidencias-empleados" },
        ],
      },
      { icon: "mdi-calendar-cursor", title: "Planificador", path: "/planificador" },
      {
        icon: "mdi-file-document-multiple-outline",
        title: "Reportes",
        children: [
          { icon: "mdi-clock", title: "Tiempo real", path: "/tiempo-real" },
          { icon: "mdi-account-details-outline", title: "Reporte de rotación", path: "/reporte-rotacion" },
          { icon: "mdi-calendar", title: "Vacaciones", path: "/vacaciones" },
          { icon: "mdi-star", title: "Aniversarios", path: "/aniversarios" },
          { icon: "mdi-progress-close", title: "Faltas", path: "/faltas" },
          { icon: "mdi-progress-alert", title: "Retardos", path: "/retardos" },
          { icon: "mdi-progress-alert", title: "Omitio Entrada", path: "/omitio-entrada" },
          { icon: "mdi-hospital-box-outline", title: "Incapacidades", path: "/incapacidad" },
          { icon: "mdi-timer-alert-outline", title: "Tiempo extra", path: "/tiempo-extra" },
          { icon: "mdi-file-document-check", title: "Registros", path: "/reporte-registros" },
          { icon: "mdi-file-account-outline", title: "Reporte de personal", path: "/reporte-personal" },
          { icon: "mdi-rolodex-outline", title: "Calendario individual", path: "/calendario" },
        ],
      },
      {
        icon: "mdi-cogs",
        title: "Configuración",
        children: [
          { icon: "mdi-cog", title: "Ajustes", path: "/ajustes" },
          { icon: "mdi-devices", title: "Dispositivos", path: "/dispositivos" },
          { icon: "mdi-calendar-star", title: "Días de vacaciones", path: "/dias-vacaciones" },
        ],
      },
    ],
    rules: {
      telefono: [(v) => !!v || "El campo es requerido", (v) => (v && v.length <= 15) || "El campo excede la longitud máxima"],
    },
    listMenu:{
      coordinador:{
        "Solicitudes": true,
      },
      sistemas:{
        "Solicitudes": true,
        "Administración de recursos humanos": true,
        "Configuración": true,
      }
    },
    listPermission:{
      coordinador:{
        "Home": true,
        "Ver todas las solicitudes": true,
        "Nueva solicitud": true,
      },
      sistemas:{
        "Home": true,
        "Ver todas las solicitudes": true,
        "Nueva solicitud": true,
        "Empleados": true,
        "Dispositivos": true,
      }
    },
    dialogs: {
      notification: false
    }
  }),
  mounted() {
    this.check();
    this.geNotification();
  },
  methods: {
    check() {
      this.username = this.$session.get("user") ? this.$session.get("user") : "";
      this.roles = this.$session.get("roles") ? this.$session.get("roles") : [];
      this.forms.telefono = this.$session.get("telefono");
    },
    logout() {
      this.loading = true;
      if (this.$session.exists()) {
        this.axios
          .post(this.host + this.path)
          .then((response) => {
            this.$session.destroy();
            this.$router.push("/login");
          })
          .catch((error) => {
            this.error = true;
            this.msgError = error.response.data.message;
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.$router.push("/login");
      }
    },
    home() {
      if (this.$route.path !== "/home") {
        this.$router.push("/home");
      }
    },
    openSubMenu(location, arrayLocation) {
      let open = false;
      for (let i = 0; i < arrayLocation.length; i++) {
        if (location === arrayLocation[i].path) {
          open = true;
          break;
        }
      }
      return open;
    },
    randomPIN() {
      this.forms.pin_seguridad = Math.floor(100000 + Math.random() * 900000).toString();
    },
    updateUser() {
      if (this.$refs.formReset.validate()) {
        this.loading = true;
        let data = {};
        if (this.selectMenuAction == 0) {
          data.telefono = this.forms.telefono;
          this.$session.set("telefono", this.forms.telefono);
        } else {
          data.pin_seguridad = this.forms.pin_seguridad;
        }
        this.axios
          .put(this.host + this.pathUpdate, { data })
          .then((response) => {
            this.alert.active = true;
            this.alert.color = "success";
            this.alert.msg = "Información de usuario actualizado exitosamente";
          })
          .catch((error) => {
            this.alert.active = true;
            this.alert.color = "error";
            this.alert.msg = error.response.data.message || "Servicio no disponible";
          })
          .finally(() => {
            this.loading = false;
            this.forms.pin_seguridad = "";
          });
      } else {
        this.alert.active = true;
        this.alert.color = "error";
        this.alert.msg = "La información es incorrecta";
      }
    },
    geNotification() {
      this.axios.get(this.host + "/api/admin/notification")
        .then((response) => {
          this.notificationData = response.data.data;
        })
        .finally(() => { });
    },
    readNotification(id) {
      if (!id) {
        this.loading = true
        let data = {
          notificacion: this.idNotification
        }
        this.axios.put(this.host + "/api/admin/notification/read", { data })
          .then((response) => {
            this.idNotification = null
            this.dialogs.notification = false
            this.geNotification()
          })
          .catch((error) => {
            this.alert.active = true;
            this.alert.color = "error";
            this.alert.msg = error.response.data.message || "Servicio no disponible";
          })
          .finally(() => {
            this.loading = false;
          });
      }
      else {
        this.idNotification = id
        this.dialogs.notification = true
      }
    },
    validPermission(title){
      let allAccess = false
      let value =true;
      for (let rol of this.$session.get("roles")) {
        if (this.listPermission[rol] ? this.listPermission[rol][title] : false) {
          value = false;
        }
        if(rol =="administrador" ){
           allAccess = true;
        }
      }
      return allAccess ? false : value;
    },
    validMenu(title){
      let allAccess = false
      let value =true;
      for (let rol of this.$session.get("roles")) {
        if (this.listMenu[rol] ? this.listMenu[rol][title] : false) {
          value = false;
        }
        if(rol =="administrador" ){
          allAccess = true
        }
      }
      return allAccess ? false : value;
    },
  },
};
</script>

<style scoped>
  .app-sidemenu {
    z-index: 9999999999 !important;
  }
  v-btn v-navigation-drawer:hover~v-btn {
    width: 2000px !important;
    height: 2000px !important;
  }
  .routerMain {
    margin-left: 3vw;
  }
</style>
